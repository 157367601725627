import React from 'react';

const Social = () => {
    return (
        <div className='container h-[100vh]'>
            <h1 className='font-nunito text-5xl text-center mt-10'> Updating...</h1>
        </div>
    );
};

export default Social;